@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?amybsy');
  src:  url('fonts/icomoon.eot?amybsy#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?amybsy') format('woff2'),
    url('fonts/icomoon.ttf?amybsy') format('truetype'),
    url('fonts/icomoon.woff?amybsy') format('woff'),
    url('fonts/icomoon.svg?amybsy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e90f";
}
.icon-weProfitGlobalIsLive:before {
  content: "\e902";
  color: #b777fa;
}
.icon-preSeedRound:before {
  content: "\e903";
  color: #fc9645;
}
.icon-firstPrototype:before {
  content: "\e907";
  color: #02db9c;
}
.icon-proofOfConcept:before {
  content: "\e908";
  color: #532980;
}
.icon-foundingOfWeprofit:before {
  content: "\e90d";
  color: #fc8845;
}
.icon-Arrow:before {
  content: "\e90e";
  color: #532980;
}
.icon-facebook:before {
  content: "\e901";
}
.icon-instagram:before {
  content: "\e905";
}
.icon-linkedin:before {
  content: "\e906";
}
.icon-rhombus:before {
  content: "\e90a";
}
.icon-semicircle:before {
  content: "\e90b";
}
.icon-twitter:before {
  content: "\e90c";
}
.icon-chack:before {
  content: "\e900";
}
.icon-openAccountLogin:before {
  content: "\e904";
}
.icon-Star:before {
  content: "\e909";
}
